<template>
    <div>
        <div class="w-25 mb-large mt-5">
            <vue-multiselect :close-on-select="true" :multiple="false" :options="businessUnits" :searchable="false"
                :placeholder="this.$t('manual_tab_divisions.all_divisions')" id="businessUnits" label="name"
                v-model="selectedBusinessUnit" selected-label="" select-label="" deselect-label=""
                @input="getStatistics" track-by="id">
                <template slot="tag" slot-scope="props">
                    <span v-text="props.option.name + ', '"></span>
                </template>
                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.name }}</span>
                        <img alt="check" class="selected" src="/svg/check.svg">
                    </div>
                </template>
            </vue-multiselect>
        </div>

        <div v-if="loading" class="text-center p-5">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div v-else>
            <div class="statistics-content-wrap-header">
                <h2>{{ $t('statistics_app_logins.login_number') }}</h2>
            </div>
            <table class="table">
                <thead>
                    <tr>
                        <th>{{ $t('statistics_app_logins.week') }}</th>
                        <th>{{ $t('statistics_app_logins.login_number_second') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="login in logins.complete" :key="`complete_${login.year_week}`">
                        <td>{{ login.week }}</td>
                        <td>{{ login.total }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="statistics-content-wrap-header">
                <h2>{{ $t('statistics_app_logins.unique_login_number') }}</h2>
            </div>
            <table class="table">
                <thead>
                    <tr>
                        <th>{{ $t('statistics_app_logins.login_number') }}</th>
                        <th>{{ $t('statistics_app_logins.login_number_second') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="login in logins.unique" :key="`unique_${login.year_week}`">
                        <td>{{ login.week }}</td>
                        <td>{{
                            login.total + ' / ' + parseFloat('' + (logins.amountOfUsers > 0 ? (login.total /
                                logins.amountOfUsers) * 100 : 0)).toFixed(2) + '%'
                            }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect/src/Multiselect';
import { BusinessUnitService } from '../../services';

export default {
    name: 'StatisticsTabLogins',

    components: {
        VueMultiselect,
    },

    data() {
        return {
            logins: {
                complete: [],
                unique: [],
                amountOfUsers: 0
            },
            selectedBusinessUnit: null,
            businessUnits: [],
            loading: false,
        };
    },

    beforeMount() {
        this.getBusinessUnits();
        this.getStatistics();
    },

    methods: {
        getStatistics() {
            this.loading = true;

            const url = '/statistics/logins' + (this.selectedBusinessUnit ? '/' + this.selectedBusinessUnit.id : '');

            window.axios.get(url)
                .then(response => {
                    this.logins = response.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.$flashMessage(this.$t('statistics_app_logins.error_loading_data'), 5000, 'error');
                });
        },

        getBusinessUnits() {
            BusinessUnitService.fetchByPermission(this.$permissions.SEE_STATISTICS)
                .then((response) => {
                    this.businessUnits = response.data;
                });
        },
    },
};
</script>